import axios from "axios";
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  SET_AUTH_REDIRECT_PATH,
  SET_STEP,
  UNSET_ERROR,
  INCOMPLETE_PROFILE,
  SEND_MSG,
} from "../types";
import { setUser } from "./profileActions";
import {
  changeFilter,
  setSelectedAPs,
  setSelectedGroups,
} from "./analyticsActions";
import { setActiveComponent } from "./templateActions";
import { addCP } from "./index";
/***************************************************
***** Auth Actions *****
# authStart: 						(set Loading = true, error = null)
# authSuccess: 					(set the token and userId) & (Loading = false, error = null)
# authFail:							(set the error & loading = false)
# logout:								(remove saved session data in local-storage) & (set token, userId = null)
# checkAuthTimeout: 		(set a timer for the session time to auto logout the userAgent)
# setAuthRedirectPath:	(set the path of redirection after authentication)
# authCheckState:				(check for session saved in LocalStorage to update the state) 										
**********************************************/

const apiUrl = `${process.env.REACT_APP_HDS_API_URL}/auth/v1`;
 //const apiUrl = `http://localhost:5000/api/auth/v1`;

const MAX_LOGIN_ATTEMPTS = 3;
const LOCKOUT_TIME = 30000; // 30 seconds in milliseconds
const expiresIn = 604800;
export const authStart = () => ({ type: AUTH_START });
export const unsetError = () => ({ type: UNSET_ERROR });
export const sendMsgToUser = (msg) => ({ type: SEND_MSG, msg });
export const setCurrentStep = (step) => {
  localStorage.setItem("step", step);
  return { type: SET_STEP, step };
};

function completeYourProfile(step) {
  localStorage.setItem("step", step);
  return { type: INCOMPLETE_PROFILE, step };
}
function setLocalStorage({
  temp,
  token,
  userId,
  email,
  account_type,
  userName = "",
  form_data = null,
  tablesPrefix = "",
}) {
  const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
  temp
    ? localStorage.setItem("tempToken", token)
    : localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("email", email);
  localStorage.setItem("userName", userName);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem("accountType", account_type);
  form_data && localStorage.setItem("form_data", JSON.stringify(form_data));
  tablesPrefix && localStorage.setItem("tablesPrefix", tablesPrefix);
}

export const authSuccess = (token, userId, userName = "") => {
  //clear on successful login
  localStorage.removeItem('loginAttempts');
  return { type: AUTH_SUCCESS, idToken: token, userId, userName };
};

export const authFail = (err) => {
  let error;
  if (err.response) {
    // in case of backend error
    error = err.response.data;
  } else if (err.message) {
    // in case of frontend error
    error = err;
  } else {
    // any other error (e.g: no connection)
    error = { message: "Something wrong happened. Please contact us" };
  }
  //toast.error(error.message ? error.message : error);
  return { type: AUTH_FAIL, error };
};

function clearLocalStorage(exclude) {
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);

    if (exclude.indexOf(key) === -1) {
      localStorage.removeItem(key);
    }
  }
}

export const logout = (removeStep = true) => {
  if (removeStep) {
    localStorage.clear();
  } else {
    clearLocalStorage(["step", "form_data", "tempToken"]);
  }
  //document.cookie = "rememberme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  return { type: AUTH_LOGOUT };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const setAuthRedirectPath = (path) => {
  return { type: SET_AUTH_REDIRECT_PATH, path: path };
};

/*
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
*/

export const authCheckState = () => {
  return (dispatch) => {
    // check if there is a non expired token
    const token = localStorage.getItem("token");
    const expirationDate = new Date(localStorage.getItem("expirationDate"));
    if (!token || expirationDate <= new Date()) return dispatch(logout(false));
    // check for remember me feature
    /*const rememberme_token = localStorage.getItem("rememberme");
    const rememberme_cookie = getCookie('rememberme');
    console.log("cookie: ",rememberme_cookie, typeof rememberme_cookie, rememberme_token);
    if(rememberme_token === "false" && rememberme_cookie !== "false") return dispatch(logout(false));*/

    // get data form localStorage and add it to the state
    const filter = JSON.parse(localStorage.getItem("analytics_filter"));
    const selectedGroups = JSON.parse(localStorage.getItem("selectedGroups"));
    const selectedAPs = JSON.parse(localStorage.getItem("selectedAPs"));
    const acs = localStorage.getItem("active_components");
    const cps = JSON.parse(localStorage.getItem("captivePortals"));
    // console.log("filter", filter);
    filter && dispatch(changeFilter(filter));
    cps && dispatch(addCP(cps));
    selectedGroups && dispatch(setSelectedGroups(selectedGroups));
    selectedAPs && dispatch(setSelectedAPs(selectedAPs));
    acs && dispatch(setActiveComponent("route", "val", true));

    // log the user in
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    try {
      dispatch(authSuccess(token, userId, userName));
      dispatch(
        checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    } catch (e) {
      console.log(e);
      dispatch(logout(false));
    }
  };
};

export const authCheckProfile = (token) => {
  console.log("Checking profile");
  return async (dispatch) => {

    const WMC_URL = `${process.env.REACT_APP_API_URL}/auth/sync`
    try {
      const payload = await decodeToken(token);
      if (!payload) throw new Error("Invalid token");
      console.log(`userId: ${payload.userId} && step: ${payload.step}`);
      console.log(
        `customerId: ${payload.customerId} && email: ${payload.email}`
      );
      let step = +payload.step;
      if (step <= 6) {
        setLocalStorage({
          temp: true,
          token,
          userId: payload.userId,
          email: payload.email,
          account_type: payload.account_type,
          userName: payload.userName,
          tablesPrefix: payload.tablesPrefix,
        });
        dispatch(completeYourProfile(step + 1));
      } else {
        setLocalStorage({
          temp: false,
          token,
          userId: payload.userId,
          email: payload.email,
          account_type: payload.account_type,
          userName: payload.userName,
          tablesPrefix: payload.tablesPrefix,
        });

        const syncResponse = axios.put(WMC_URL, { prefix: payload.tablesPrefix});

        dispatch(authSuccess(token, payload.userId, payload.userName));
        dispatch(checkAuthTimeout(expiresIn));
      }
    } catch (err) {
      dispatch(authFail({ message: "Not a valid token" }));
    }
  };
};

function login({ email, password }) {
  return async (dispatch) => {
    let loginAttempts = JSON.parse(localStorage.getItem('loginAttempts') || '{}');
    const now = Date.now();
    
    //print login attempts
    console.log('Current login attempts:', {
      attemptCount: loginAttempts.count || 0,
      lastAttempt: loginAttempts.lastAttempt ? new Date(loginAttempts.lastAttempt).toLocaleString() : 'None',
      lockoutUntil: loginAttempts.lockoutUntil ? new Date(loginAttempts.lockoutUntil).toLocaleString() : 'None',
      remainingAttempts: MAX_LOGIN_ATTEMPTS - (loginAttempts.count || 0)
    });

    if (loginAttempts.lockoutUntil && loginAttempts.lockoutUntil < now) {
      console.log('Lockout expired, resetting attempts');
      loginAttempts = {};
    } else if (loginAttempts.lockoutUntil && loginAttempts.lockoutUntil > now) {
      const remainingTime = Math.ceil((loginAttempts.lockoutUntil - now) / 1000);
      console.log(`User locked out for ${remainingTime} more seconds`);
      return dispatch(authFail({ 
        message: `Too many login attempts. Please wait ${remainingTime} seconds before trying again.` 
      }));
    }

    loginAttempts.count = (loginAttempts.count || 0) + 1;
    loginAttempts.lastAttempt = now;

    console.log(`Login attempt ${loginAttempts.count} of ${MAX_LOGIN_ATTEMPTS}`);

    if (loginAttempts.count >= MAX_LOGIN_ATTEMPTS) {
      console.log('Max attempts reached, implementing lockout');
      loginAttempts.lockoutUntil = now + LOCKOUT_TIME;
      localStorage.setItem('loginAttempts', JSON.stringify(loginAttempts));
      return dispatch(authFail({ 
        message: 'Too many login attempts. Please wait 30 seconds before trying again.' 
      }));
    }

    localStorage.setItem('loginAttempts', JSON.stringify(loginAttempts));
    
    dispatch(authStart());
    const authData = { email, password };
    let url = `${apiUrl}/login`;

    const WMC_URL = `${process.env.REACT_APP_API_URL}/auth/sync`;

    try {
      const response = await axios.post(url, authData);
      console.log('Login successful, resetting attempts');
      localStorage.removeItem('loginAttempts');
      
      const payload = await decodeToken(response.data.token);
      if (!payload) throw new Error("Invalid token");

      let step = +payload.step;
      if (step <= 6) {
        setLocalStorage({
          temp: true,
          token: response.data.token,
          userId: payload.userId,
          email: payload.email,
          account_type: payload.account_type,
          userName: payload.userName,
          tablesPrefix: payload.tablesPrefix,
        });
        dispatch(completeYourProfile(step + 1));
      } else {
        setLocalStorage({
          temp: false,
          token: response.data.token,
          userId: payload.userId,
          email: payload.email,
          account_type: payload.account_type,
          userName: payload.userName,
          tablesPrefix: payload.tablesPrefix,
        });

        const syncResponse = axios.put(WMC_URL, { prefix: payload.tablesPrefix});

        dispatch(authSuccess(response.data.token, payload.userId, payload.userName));
        dispatch(checkAuthTimeout(expiresIn));
      }
    } catch (err) {
      console.log('Login failed');
      dispatch(authFail(err));
    }
  };
}

function validatePassword(password){
  const minLength = 8;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumbers = /[0-9]/.test(password);
  const hasSpecialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  if (password.length < minLength || !hasLowerCase || !hasUpperCase || !hasNumbers || !hasSpecialCharacters) {
    return "Password must be atleast 8 characters long and contain atleast one upper case letter, one lower case letter, one number, and one special character";
  }else{
    return null;
  }
}

function createAnAccount({ email, password1, password2 }) {
  return (dispatch) => {
    dispatch(authStart());
    if (password1 !== password2) {
      return dispatch(authFail({ message: "These passwords does not match" }));
    }
    const passwordError = validatePassword(password1);
    if(passwordError !== null){
      return dispatch(authFail({ message: passwordError }));
    }
    const authData = { email, password: password1 };
    let url = `${apiUrl}/sign-up`;
    axios
      .post(url, authData)
      .then((response) => {
        decodeToken(response.data.token).then((payload) => {
          if (!payload) throw new Error("Invalid token");

          console.log(response);
          setLocalStorage({
            temp: true,
            token: response.data.token,
            userId: response.data.userId,
            email,
            account_type: response.data.account_type,
            tablesPrefix: payload.tablesPrefix,
          });
          localStorage.setItem("email", email);
          dispatch(setUser(response.data.userId, email));
          dispatch(completeYourProfile(1));
        });
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

function forgotPassword({ email }) {
  return (dispatch) => {
    dispatch(authStart());
    const authData = { email };
    let url = `${apiUrl}/forgot-password`;
    console.log("response");
    axios
      .post(url, authData)
      .then((response) => {
        console.log("response");
        dispatch(
          sendMsgToUser(`We have sent you an email. Go to your email 
				and Click on reset password`)
        );
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

function changePassword({ password1, password2 }) {
  return (dispatch) => {
    dispatch(authStart());
    if (password1 !== password2) {
      return dispatch(authFail({ message: "These passwords does not match" }));
    }
    const userId = +localStorage.getItem("userId");
    if (!userId)
      return dispatch(
        authFail({ message: "You can not change your password right now" })
      );

    const authData = { password: password1, userId: userId };
    let url = `${apiUrl}/change-password`;
    axios
      .post(url, authData)
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          dispatch(
            sendMsgToUser(
              "You have change your password successfully. Try to login now"
            )
          );
        }
      })
      .catch((err) => dispatch(authFail(err)));
  };
}

export const handleAuthentication = (authType, formData) => {
  switch (authType) {
    case "login":
      return login(formData);
    case "signup":
      return createAnAccount(formData);
    case "forgotPassword":
      return forgotPassword(formData);
    case "changePassword":
      return changePassword(formData);
    default:
      return false;
  }
};

export const decodeToken = (token) => {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/auth/verify-token`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch(() => false);
};

export const authCheckResetToken = (token) => {
  console.log("Checking reset token");
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const payload = await decodeToken(token);
      if (!payload) throw new Error("Not a valid token");
      let change = payload.changePassword;
      console.log("change = ", change);
      console.log("typeof change : ", typeof change);
      if (change) {
        setLocalStorage({
          temp: true,
          token,
          userId: payload.userId,
          email: payload.email,
          tablesPrefix: payload.tablesPrefix,
        });
        dispatch(sendMsgToUser(`You can change Your password Now`));
      } else {
        dispatch(authFail({ message: "Not a valid token" }));
      }
    } catch (err) {
      dispatch(authFail({ message: "Not a valid token" }));
    }
  };
};
